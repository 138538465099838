import { Asesor } from "../business/models/Asesor"
import httpService from "./Http"

class AsesoresApi{

    static async crear(usuario : Asesor){
        return await new httpService().post('v1/asesor/nuevo', usuario)
    }
    static async get(asesor_id : string){
        return await new httpService().get(`v1/asesor/${asesor_id}`)
    }
    static async getAll(){
        return await new httpService().get('v1/asesores')
    }
    static async update(id : string ,usuario : Asesor){
        return await new httpService().put(`v1/asesor/${id}`, usuario)
    }
    static async crear_usuario_global(usuario : { uid : string, password : string }){
        return await new httpService().post(`admin/asesor/nuevo`, usuario)
    }
    static async cambiarPassword(uid : string, newPassword : string){
        return await new httpService().put(`admin/asesor/cambiarPassword/${uid}`, {newPassword})
    }
    static async cambiarEstatus(id : number, estatus : string){
        return await new httpService().put(`v1/asesor/estatus/${id}`, {estatus})
    }
    static async reenviarAcceso(id : number){
        return await new httpService().post(`v1/asesor/reenviar_login`, {id_asesor :id})
    }

}
export default AsesoresApi